import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackService {

  private defaultSnackConfig: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    panelClass: ['successNotification']
  };

  constructor(
    private _snack: MatSnackBar
  ) { }

  public success(message: string, config: MatSnackBarConfig = {}, actionLabel: string = 'OK'): MatSnackBarRef<any> {
    const snackConfig: MatSnackBarConfig = Object.assign(
      {},
      this.defaultSnackConfig,
      config,
      { panelClass: 'successNotification' }
    );

    return this._snack.open(
      message,
      actionLabel,
      snackConfig
    );
  }

  public error(message: string, config: MatSnackBarConfig = {}, actionLabel: string = 'OK'): MatSnackBarRef<any> {
    const snackConfig: MatSnackBarConfig = Object.assign(
      {},
      this.defaultSnackConfig,
      config,
      { panelClass: ['mat-toolbar', 'mat-warn'] }
    );

    return this._snack.open(
      message,
      actionLabel,
      snackConfig
    );
  }
}
