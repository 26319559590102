<video *ngIf="video.type === 'video'"
    #video 
    height="100%" width="100%"
    controls controlsList="nodownload"
    (keypress.space)="togglePause()"
    (keyup.f)="toggleFullscreen()"
></video>

<iframe *ngIf="video.type === 'youtube'"
    [src]="sanitizeUrl(video.url)"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
></iframe>