import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/core/services/api/login.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginRequest } from '../core/models/login-request.model';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActionSheetComponent, ActionSheetItem } from 'src/app/shared/action-sheet/action-sheet.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  isNewAccount = false;
  verified = false;


  constructor(
    public translate: TranslateService,
    private bottomSheet: MatBottomSheet,
    private loginService: LoginService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.isNewAccount = !!this.route.snapshot.paramMap.get('isNewAccount');
    this.verified = !!this.route.snapshot.paramMap.get('verified');
  }

  ngOnInit(): void {}

  submitForm() {
    const body: LoginRequest = { username: this.username, password: this.password };
    this.loginService.post(body).subscribe(
      (data: any) => {
        if(data?.AuthenticationResult) {
            this.authService.login(data.AuthenticationResult);
        } else {
            // TODO: error snack
        }
      },
      (error: any) => {
        console.log("Error returned: ", error);
      }
    )
  }

  presentLanguageMenu(): MatBottomSheetRef {
    const languages: ActionSheetItem[] = this.translate.getLangs().map(
      (lang) => {
        return {
          icon: "",
          label: {
            en: "English",
            wel: "Cymraeg",
            // Define new language menu options here
          }[lang],
          handler: () => this.translate.use(lang)
        }
      }
    );

    const actionSheet = this.bottomSheet.open(ActionSheetComponent, { data: languages });
    return actionSheet;
  }

}
