import { LoginRequest } from 'src/app/core/models/login-request.model';
import { LoginService } from './../core/services/api/login.service';
import { AuthService } from './../core/services/auth.service';
import { PasswordService } from './../core/services/api/password.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	
	username: string;
	code: string;
	password: string;
	repeatedPassword: string;
	
	isPasswordResetRequested = false;

	constructor(
		private passwordService: PasswordService,
		private loginService: LoginService,
		private authService: AuthService
	) {}
		
	ngOnInit(): void {}
	
	requestResetCode(): void {
		this.passwordService.forgotPasswordPost({ username: this.username }).subscribe(
			success => {
				this.isPasswordResetRequested = true;
			},
			error => {}
		);
	}

	postNewPassword() {
		this.passwordService.forgotPasswordPut({
			username: this.username,
			code: this.code,
			password: this.password
		}).subscribe(
			success => {
				// TODO: success notification
				this.login();
			},
			error => {}
		);
	}

	login() {
		const body: LoginRequest = { username: this.username, password: this.password };
		this.loginService.post(body).subscribe(
		  (data: any) => {
			if(data?.AuthenticationResult) this.authService.login(data.AuthenticationResult);
		  },
		  (error: any) => {
			console.log("Error returned: ", error);
		  }
		)
	}

	refreshFieldValidity(control: FormControl) {
		// Force it to the end of stack
		// Otherwise input value change will not be propagated in time to our custom directive
		setTimeout(() => control.updateValueAndValidity({ onlySelf: true, emitEvent: true }));
	}
}
		