// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// This is a test comment

export const environment = {
  production: false,
  stripeKey: 'pk_test_51I3IECLoXN9OLJp7cQc8BgwJJp9HKah4w7GtYq2wXaDl5LpZC3J7NXRZyG6Wm1kknh7fiLrPBppnK5eNfvcCi5Ds00c8gcRAPR',
  baseUrl: 'https://i0ubdux3ol.execute-api.eu-west-2.amazonaws.com/development',
  available_languages: ['en', 'wel'],
  default_language: 'en',
  welshLanguageDomain:'randomwelshaddress'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
