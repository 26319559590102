import { Video } from './../app/core/models/video.model';

export const videos: { [key: string]: Video } = {
    //introduction videos english and welsh
    "introduction": {
        type: "youtube",
        slug: "introduction",
        title: "Introduction to Wellbeing Matters",
        subtitle: "A video giving an introduction to what Wellbeing Matters is",
        category: "move",
        language: "en",
        url: "https://www.youtube.com/embed/G4Z6rZgQ_H0"
    },
    "cyflwyniad": {
        type: "youtube",
        slug: "cyflwyniad",
        title: "Cyflwyniad i Faterion Lles",
        subtitle: "Fideo yn rhoi cyflwyniad i beth yw Wellbeing Matters",
        category: "move",
        language: "wel",
        url: "https://www.youtube.com/embed/vd7kGNYDs60"
    },
    //webinar bit, english and welsh
    "webinar": {
        type: "youtube",
        slug: "webinar",
        title: "Webinar",
        subtitle: "A longer webinar giving a deeper introduction to some of the concepts featured in Wellbeing Matters",
        category: "move",
        language: "en",
        url: "https://www.youtube.com/embed/m4LlD30gGno"
    },
    "gweminar": {
        type: "youtube",
        slug: "gweminar",
        title: "Gweminar",
        subtitle: "Gweminar hirach yn rhoi cyflwyniad dyfnach i rai o'r cysyniadau a welir yn Wellbeing Matters",
        category: "move",
        language: "wel",
        url: "https://www.youtube.com/embed/m4LlD30gGno"
    },
    // other videos
    "caredigrwydd": {
        type: 'video',
        slug: 'caredigrwydd',
        title: 'Caredigrwydd',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Caredigrwydd.m3u8'
    },
    "empathi": {
        type: 'video',
        slug: 'empathi',
        title: 'Empathi',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Empathi.m3u8'
    },
    "empathy": {
        type: 'video',
        slug: 'empathy',
        title: 'Empathy',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Empathy.m3u8'
    },
    "gratitude": {
        type: 'video',
        slug: 'gratitude',
        title: 'Gratitude',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Gratitude.m3u8'
    },
    "kindness": {
        type: 'video',
        slug: 'kindness',
        title: 'Kindness',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Kindness.m3u8'
    },
    "llwyddiant_lles": {
        type: 'video',
        slug: 'llwyddiant_lles',
        title: 'Llwyddiant',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Llwyddiant+LLES.m3u8'
    },
    "meaning": {
        type: 'video',
        slug: 'meaning',
        title: 'Meaning',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Meaning.m3u8'
    },
    "optimism": {
        type: 'video',
        slug: 'optimism',
        title: 'Optimism',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Optimism.m3u8'
    },
    "optimistiaeth": {
        type: 'video',
        slug: 'optimistiaeth',
        title: 'Optimistiaeth',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Optimistiaeth.m3u8'
    },
    "savouring": {
        type: 'video',
        slug: 'savouring',
        title: 'Savouring',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Savouring.m3u8'
    },
    "sawru": {
        type: 'video',
        slug: 'sawru',
        title: 'Sawru',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Sawru.m3u8'
    },
    "wellbeing_matters": {
        type: 'video',
        slug: 'wellbeing_matters',
        title: 'Wellbeing',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Wellbeing+Matters.m3u8'
    },
    "ystyr": {
        type: 'video',
        slug: 'ystyr',
        title: 'Ystyr',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/Ystyr.m3u8'
    },
    "diolchgarwch": {
        type: 'video',
        slug: 'diolchgarwch',
        title: 'Diolchgarwch',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/diolchgarwch.m3u8'
    },
    "wellbeing_matters_short_0": {
        type: 'video',
        slug: 'wellbeing_matters_short_0',
        title: 'wellbeing',
        url: 'https://well-converted-videos.s3.eu-west-2.amazonaws.com/wellbeing+matters+short_0.m3u8'
    }
}