import { Subscription, interval as observableInterval, Observable, Subject } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from "@angular/core";
import { RefreshRequest } from 'src/app/core/models/refresh-request.model';
import { TokenService } from 'src/app/core/services/api/token.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private initialized: boolean = false;
    private refreshSubscription: Subscription;

    private loginSubject: Subject<boolean> = new Subject();
    public loginEvent$: Observable<boolean> = this.loginSubject.asObservable();

    public idToken: string;
    private decodedIdToken: string;
    private userRefreshToken: string;

    constructor(
        private jwtHelper: JwtHelperService,
        private tokenService: TokenService
    ) {}

    public isAuthenticated(): Promise<boolean> {
        if (!this.initialized && localStorage.getItem('refreshToken')) {
            return this.refreshToken(localStorage.getItem('refreshToken')).toPromise().then(
                isAuth => {
                    if(isAuth) {
                        return Promise.resolve(true);
                    } else {
                        return Promise.resolve(false);
                    }
                }
            );
        }
        this.initialized = true;

        if (!this.idToken || !this.decodedIdToken) return Promise.resolve(false);

        return Promise.resolve(!this.jwtHelper.isTokenExpired(this.idToken));
    }

    public login(data: any) {
        this.idToken = data.IdToken;
        localStorage.setItem('token', this.idToken)
        this.decodedIdToken = this.jwtHelper.decodeToken(data.IdToken);
        if (data.RefreshToken) {
            this.userRefreshToken = data.RefreshToken;
            localStorage.setItem("refreshToken", this.userRefreshToken);
            this.scheduleRefresh(data.RefreshToken, data.ExpiresIn);
        } else {
            this.scheduleRefresh(localStorage.getItem('refreshToken'), data.ExpiresIn);
        }
        
        this.initialized = true;
        this.loginSubject.next(true);
    }

    public logout() {
        this.decodedIdToken = null;
        this.userRefreshToken = null;
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        sessionStorage.clear();
        this.unScheduleRefresh();

        this.loginSubject.next(false);
    }

    private refreshToken(refreshToken: string): Observable<boolean> {
        let req: RefreshRequest = { token: refreshToken };
        return this.tokenService.post(req)
            .pipe(switchMap((newToken: any) => {
                if (newToken) {
                    this.initialized = true;
                    this.login(newToken);
                    return [true];
                }
            }),
            catchError((err, caught) => {
                console.log(err)
                this.logout();
                return [false];
            })
        );
    }

    private scheduleRefresh(refreshToken: string, expiresIn: number) {
        if (expiresIn === undefined || expiresIn === null)
            expiresIn = 3600;
        if (!this.refreshSubscription || this.refreshSubscription.closed) {
            this.refreshSubscription = observableInterval((expiresIn / 2) * 1000).pipe(switchMap(() =>
                this.refreshToken(refreshToken))).subscribe();
        }
    }

    private unScheduleRefresh() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    get userRoles(): string[] {
        return this.decodedIdToken?.['cognito:groups'] || [];
    }

    get userId(): string {
        return this.decodedIdToken["cognito:username"];
    }

    get userName(): string {
        return this.decodedIdToken["name"];
    }

    get userEmail(): string {
        return this.decodedIdToken["email"];
    }

    get userStripeCustomerId(): string {
        return this.decodedIdToken?.['custom:stripeCustomerId'];
    }

    get userStripePaymentId(): string {
        return this.decodedIdToken?.['custom:stripePaymentId'];
    }

    get userStripePaymentStatus(): string {
        return this.decodedIdToken?.['custom:stripePaymentStatus'];
    }

    get isPaymentConfirmed(): boolean {
        return this.decodedIdToken?.['custom:stripePaymentStatus'] === 'paid';
    }
}