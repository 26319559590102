import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SignUpRequest } from './../core/models/sign-up-request.model';
import { SignUpService } from '../core/services/api/sign-up.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

    email: string;
    password: string;
    name: string;
  
    constructor(
      private signUpService: SignUpService,
      private router: Router,
      private dialog: MatDialog
    ) {}
  
    ngOnInit(): void {}
  
    submitForm() {
      const body: SignUpRequest = { email: this.email, password: this.password, name: this.name };
      this.signUpService.post(body).subscribe(
        (data: any) => {
          this.router.navigate(['/login', { isNewAccount: true }]);
        },
        (error: any) => {
          console.log("Error returned: ", error);
        }
      )
    }
}
