import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

export interface ActionSheetItem {
    icon: string;
    label: string;
    handler?: Function;
}

@Component({
    selector: 'app-action-sheet',
    templateUrl: './action-sheet.component.html',
    styleUrls: ['./action-sheet.component.scss']
})
export class ActionSheetComponent implements OnInit {
    
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: ActionSheetItem[],
        private sheetRef: MatBottomSheetRef
    ) {}
        
    ngOnInit(): void {}
        
    public select(option: ActionSheetItem) {
        this.sheetRef.dismiss(option);
        option.handler?.();
    }
}
