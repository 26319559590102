import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface PasswordResetRequest {
    username: string;
    password: string;
    code: string;
}

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  private url = `${environment.baseUrl}/account/forgotpassword`;

  constructor(
    private _httpClient: HttpClient
  ) {}

  forgotPasswordPost(request: { username }){
    return this._httpClient.post<{ username }>(this.url, request);
  }

  forgotPasswordPut(request: PasswordResetRequest){
    return this._httpClient.put<PasswordResetRequest>(this.url, request);
  }
}
