import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginRequest } from 'src/app/core/models/login-request.model';

@Injectable({
  providedIn: 'root'
})
export class MockLoginService {

  constructor() { }

  baseUrl: string = environment.baseUrl + '/login';

  post(loginRequest: LoginRequest, params?: any) {
    return of(1);
  }
}
