import { BackgroundImageService } from './../core/services/background-image.service';
import { slideInAnimation } from './../../animations';
import { Router, RouterOutlet } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActionSheetComponent, ActionSheetItem } from 'src/app/shared/action-sheet/action-sheet.component';
import { AuthService } from '../core/services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: [
        slideInAnimation
    ]
})
export class MainComponent implements OnInit, AfterViewInit {

    @ViewChild('sidenav') sidenavRef: MatSidenav;

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.setWindowScrollHeight();
    }

    constructor(
        public translate: TranslateService,
        public router: Router,
        public auth: AuthService,
        private bottomSheet: MatBottomSheet,
        public backgroundImageService: BackgroundImageService
    ) {
        translate.use(localStorage.getItem(`language`) || translate.defaultLang);
    }

    ngOnInit(): void {
        this.setWindowScrollHeight();
    }

    setWindowScrollHeight(): void {
        const windowHeight = window.innerHeight.toString() + 'px';

        const sideNavCont = document.getElementsByClassName('mat-sidenav-container');
        for (let i = 0; i < sideNavCont.length; i++) {
            document.getElementsByClassName('mat-sidenav-container')[i].setAttribute('style', 'height:' + windowHeight);
        }
    }

    ngAfterViewInit(): void {
        this.setWindowScrollHeight();
    }

    presentUserMenu(): MatBottomSheetRef {
        const menuItems: ActionSheetItem[] = [
            {
                icon: 'translate',
                label: this.translate.instant('sheet_menu.select_language'),
                handler: () => this.presentLanguageMenu()
            },
            {
                icon: 'power_settings_new',
                label: this.translate.instant("sheet_menu.logout"),
                handler: () => this.auth.logout()
            }
        ];

        const actionSheet = this.bottomSheet.open(ActionSheetComponent, { data: menuItems });
        return actionSheet;
    }

    presentLanguageMenu(): MatBottomSheetRef {
        const languages: ActionSheetItem[] = this.translate.getLangs().map(
            (lang) => {
                return {
                    icon: "",
                    label: {
                        en: "English",
                        wel: "Cymraeg",
                        // Define new language menu options here
                    }[lang],
                    handler: () => this.translate.use(lang)
                }
            }
        )

        const actionSheet = this.bottomSheet.open(ActionSheetComponent, { data: languages });
        return actionSheet;
    }

    public navigate(url: string): void {
        this.router.navigate([url]);
        this.sidenavRef.close();
    }

    prepareRoute(outlet: RouterOutlet): string {
        return outlet?.activatedRouteData?.['animation'];
    }
}
