<header>
    <img class="logo" src="assets/images/gwylanlogo.jpg" alt="">
    <span class="title">{{ 'app_title' | translate }}</span>
</header>
<mat-card class="mat-elevation-z0">
    <mat-card-header [class.verifyCTA]="isNewAccount">
        <mat-list-item id="loginLang">
            <button mat-icon-button color="primary" aria-label="Select language" 
            (click)="presentLanguageMenu()">
                <img class="imgClass" src="assets/images/lang.png">
            </button>
            <span id="langImg"  (keyup.enter)="presentLanguageMenu()" tabindex="0" (click)='presentLanguageMenu()'>{{ 'sheet_menu.select_language' | translate }}</span>
           
        </mat-list-item>
        <mat-card-subtitle *ngIf="!isNewAccount">{{ 'auth.loginCTA' | translate }}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="isNewAccount">{{ 'auth.verifyCTA' | translate }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content (keydown.enter)="submitForm()">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.username' | translate }}</mat-label>
            <mat-icon matPrefix>mail_outline</mat-icon>
            <input matInput [(ngModel)]="username" required email type="email">
            <mat-error>{{ 'auth.username_validation_error' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.password' | translate }}</mat-label>
            <mat-icon matPrefix>vpn_key</mat-icon>
            <input matInput [(ngModel)]="password" type="password" required>
            <mat-error>{{ 'auth.password_validation_error' | translate }}</mat-error>
        </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button color="accent" (click)="submitForm()">{{ 'auth.login' | translate }}</button>
    </mat-card-actions>
    <a class="register" routerLink="/sign-up">
        {{ 'auth.registerCTA' | translate }}
    </a>
    <a class="register" routerLink="/forgot-password">
        {{ 'auth.forgot_password' | translate }}
    </a>
</mat-card>