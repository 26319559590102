import { Injectable } from '@angular/core';
import { Video } from '../models/video.model';
import { videos } from 'src/assets/videos';

@Injectable({
    providedIn: 'root'
})
export class VideoService {
    
    private videos: { [key: string]: Video } = videos;

    constructor() {}
    
    get(keys: string[]): Video[] {
        return keys.map(key => this.videos[key]);
    }

    getOne(key: string): Video {
        return this.videos[key];
    }
}
