import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class MockAuthService {

    private loginSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public loginEvent$: Observable<boolean> = this.loginSubject.asObservable();

    constructor() {}

    public isAuthenticated(): boolean {
        return true;
    }

    public login(data: any) {
        this.loginSubject.next(true);
    }

    public logout() {
        this.loginSubject.next(false);
    }
}