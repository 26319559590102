import { SignUpRequest } from './../../models/sign-up-request.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

    constructor(public _httpClient: HttpClient) { }

    baseUrl: string = environment.baseUrl + '/registration';
  
    post(signUpRequest: SignUpRequest, params?: any) {
      return this._httpClient.post<SignUpRequest>(this.baseUrl, signUpRequest, { params: params });
    }
}
