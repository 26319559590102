import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private authService: AuthService,
        private router: Router
    ) {}

	async canActivate() {
        const isAuthenticated = await this.authService.isAuthenticated();
        if(isAuthenticated) {
            return true;
        }
        else {
            return this.router.createUrlTree(['/login']);
        }
	}
}
