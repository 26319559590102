import { StripeService } from './../core/services/api/stripe.service';
import { StripeService as Stripe } from 'ngx-stripe';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

    constructor(
        public auth: AuthService,
        public router: Router,
        public _location: Location,
        private stripeService: StripeService,
        private stripe: Stripe
    ) {}
    
    ngOnInit(): void {}
    
    
    onClick() {
        this._location.back();
    }

    redirectToCheckout() {
        this.stripeService.getCheckoutSession().subscribe(
            ({ id: sessionId }) => {
                this.stripe.redirectToCheckout({ sessionId }).subscribe(
                    error => {
                        console.error(error);
                    }
                );
            }
        );
    }

    redirectToCustomerPortal() {
        this.stripeService.getCustomerPortalSession().subscribe(
            ({ url }) => window.location.href = url
        )
    }
}
