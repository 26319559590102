<header>
    <img class="logo" src="assets/images/gwylanlogo.jpg" alt="">
    <span class="title">{{ 'app_title' | translate }}</span>
</header>
<mat-card class="mat-elevation-z0">
    <mat-card-header [class.reset-requested]="isPasswordResetRequested">
        <mat-card-subtitle *ngIf="!isPasswordResetRequested">{{ 'auth.reset_your_password' | translate }}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="isPasswordResetRequested">{{ 'auth.password_reset_intro' | translate }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content (keydown.enter)="
        isPasswordResetRequested
        ? (code_field.invalid || email_field.invalid || password_field.invalid || confirm_password_field.invalid || password !== repeatedPassword) ? null : postNewPassword()
        : email_field.invalid ? null : requestResetCode()
    ">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.username' | translate }}</mat-label>
            <mat-icon matPrefix>mail_outline</mat-icon>
            <input [disabled]="isPasswordResetRequested" #email_field="ngModel" matInput [(ngModel)]="username" required email type="email">
            <mat-error>{{ 'auth.username_validation_error' | translate }}</mat-error>
        </mat-form-field>
        <div [hidden]="!isPasswordResetRequested">
            <mat-form-field appearance="fill">
                <mat-label>{{ 'auth.password_reset_code' | translate }}</mat-label>
                <mat-icon matPrefix>verified_user</mat-icon>
                <input #code_field="ngModel" matInput [(ngModel)]="code" type="text" required>
                <mat-error>{{ 'auth.code_validation_error' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'auth.new_password' | translate }}</mat-label>
                <mat-icon matPrefix>vpn_key</mat-icon>
                <input #password_field="ngModel" matInput type="password" required minlength="8" [(ngModel)]="password" (ngModelChange)="refreshFieldValidity(confirm_password_field.control)">
                <mat-error>{{ 'auth.password_validation_error' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'auth.confirm_new_password' | translate }}</mat-label>
                <mat-icon matPrefix>vpn_key</mat-icon>
                <input #confirm_password_field="ngModel" matInput [validateEqual]="password" [(ngModel)]="repeatedPassword" type="password">
                <mat-error>{{ 'auth.password_reset_validation_error' | translate }}</mat-error>
            </mat-form-field>
        </div>
    </mat-card-content>
    <mat-card-actions (click)="email_field.control.markAsTouched(); isPasswordResetRequested ? code_field.control.markAsTouched() || password_field.control.markAsTouched() || confirm_password_field.control.markAsTouched() : null">
        <button *ngIf="!isPasswordResetRequested" [disabled]="email_field.invalid" mat-flat-button color="accent" (click)="requestResetCode()">{{ 'auth.request_password_reset' | translate }}</button>
        <button *ngIf="isPasswordResetRequested" [disabled]="code_field.invalid || password_field.invalid || confirm_password_field.invalid || password !== repeatedPassword" mat-flat-button color="accent" (click)="postNewPassword()">{{ 'auth.update_password' | translate }}</button>
    </mat-card-actions>
    <a class="register" routerLink="/sign-up">
        {{ 'auth.registerCTA' | translate }}
    </a>
    <a class="register" routerLink="/login">
        {{ 'auth.use_existing_account' | translate }}
    </a>
</mat-card>