import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location} from '@angular/common';


@Component({
  selector: 'app-introduction-page',
  templateUrl: './introduction-page.component.html',
  styleUrls: ['./introduction-page.component.scss']
})
export class IntroductionPageComponent implements OnInit {

  constructor(
    public activatedRoute: ActivatedRoute,
    public auth: AuthService,
    public router: Router,
    public translate: TranslateService 
  ) {}

  ngOnInit(): void {}
}
