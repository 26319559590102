<h1 mat-dialog-title>{{ config.title }}</h1>
<div mat-dialog-content>
    <p *ngIf="config.prompt">{{ config.prompt }}</p>
    <mat-form-field>
        <mat-label *ngIf="config.inputLabel">{{ config.inputLabel }}</mat-label>
        <input matInput [(ngModel)]="inputText" (keyup.enter)="confirm()">
        <mat-hint *ngIf="config.inputHint">{{ config.inputHint }}</mat-hint>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>{{ 'dialog.cancel' | translate }}</button>
    <button mat-button (click)="confirm()">{{ 'dialog.ok' | translate }}</button>
</div>