import { SnackService } from '../services/snack.service';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {

    private excludedEndpoints: string[] = [
        'https://doua4ano84.execute-api.eu-west-2.amazonaws.com/Test'
    ];
    private excludedStatusCodes: number[] = [404];

    constructor(
        private inj: Injector,
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const _snack = this.inj.get(SnackService);
        // Exceptions
        if (this.excludedEndpoints.some(excludedEndpoint => req.url.includes(excludedEndpoint))) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            // Notify user and throw
            catchError((error: HttpErrorResponse) => {
                if (!this.excludedStatusCodes.includes(error.status)) {
                    const message: string = error?.error?.detail || 'Something went wrong!';
                    _snack.error(message);
                }

                return throwError(error.error);
            })
        );
    }


}
