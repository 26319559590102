import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type TextPromptConfig = {
    title: string;
    prompt?: string;
    inputLabel?: string;
    inputHint?: string;
};

@Component({
    selector: 'app-text-prompt-dialog',
    templateUrl: './text-prompt-dialog.component.html',
    styleUrls: ['./text-prompt-dialog.component.scss']
})
export class TextPromptDialogComponent implements OnInit {
    
    public inputText: string;

    constructor(
        private dialogRef: MatDialogRef<TextPromptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public config: TextPromptConfig
    ) {}
        
    ngOnInit(): void {}
        
    confirm(): void {
        if(this.inputText) {
            this.dialogRef.close(this.inputText)
        }
    }    
}
    