import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[validateEqual]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidatorDirective), multi: true }
    ]
})
export class EqualValidatorDirective implements Validator {
    
    @Input() validateEqual: string;
    
    constructor() {}
    
    validate(control: AbstractControl): { [key: string]: any } {
        if (this.validateEqual && this.validateEqual !== control.value) {
            return { validateEqual: false }
        }

        return null;
    }
}