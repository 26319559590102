import { InviteCodeDialogComponent } from '../code-dialog/invite-code-dialog.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material/material.module';
import { ActionSheetComponent } from './action-sheet/action-sheet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListComponent } from './list/list.component';
import { TextPromptDialogComponent } from './text-prompt-dialog/text-prompt-dialog.component';

@NgModule({
  declarations: [
    ActionSheetComponent,
    ListComponent,
    TextPromptDialogComponent,
    InviteCodeDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    ActionSheetComponent,
    ListComponent,
    TextPromptDialogComponent,
    InviteCodeDialogComponent
  ]
})
export class SharedModule {}
