import { TranslateService } from '@ngx-translate/core';
import { StripeService } from './../../core/services/api/stripe.service';
import { StripeService as Stripe } from 'ngx-stripe';
import { Video } from './../../core/models/video.model';
import { Category } from './../../core/models/category.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';
import { videos } from 'src/assets/videos';
import { pdfs } from 'src/assets/pdfs';

interface ListConfig {
    headerTextProperty: string;
    titleTextProperty: string;
    durationProperty: string;
}

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

    list: { [key: string]: Category | Video };
    listConfig: ListConfig = {
        headerTextProperty: 'subtitle',
        titleTextProperty: 'title',
        durationProperty: 'duration'
    };

    pageTitle = '';
    pageItemsClass = '';

    public isHomepage = true;

    constructor(
        public activatedRoute: ActivatedRoute,
        public auth: AuthService,
        public router: Router,
        translate: TranslateService,
        private stripeService: StripeService,
        private stripe: Stripe,
        public _location: Location
    ) {



        let items: { [key: string]: Category } = {
            en: {
                part1:{
                    type: 'category',
                    title: 'home_page.part_1',
                    slug: 'part1',
                    items: {
                        'meaning': videos.meaning,
                        'part1_preschool': pdfs.part1_preschool,
                        'part1_foundation': pdfs.part1_foundation,
                        'part1_primary': pdfs.part1_primary,
                        'part1_secondary': pdfs.part1_secondary,
                    }
                },
                part2:{
                    type: 'category',
                    title: 'home_page.part_2',
                    slug: 'part2',
                    items: {
                        'gratitude': videos.gratitude,
                        'part2_preschool': pdfs.part2_preschool,
                        'part2_foundation': pdfs.part2_foundation,
                        'part2_primary': pdfs.part2_primary,
                        'part2_secondary': pdfs.part2_secondary,
                    }
                },
                part3:{
                    type: 'category',
                    title: 'home_page.part_3',
                    slug: 'part3',
                    items: {
                        'empathy': videos.empathy,
                        'part3_preschool': pdfs.part3_preschool,
                        'part3_foundation': pdfs.part3_foundation,
                        'part3_primary': pdfs.part3_primary,
                        'part3_secondary': pdfs.part3_secondary,
                    }
                },
                part4:{
                    type: 'category',
                    title: 'home_page.part_4',
                    slug: 'part4',
                    items: {
                        'kindness': videos.kindness,
                        'part4_preschool': pdfs.part4_preschool,
                        'part4_foundation': pdfs.part4_foundation,
                        'part4_primary': pdfs.part4_primary,
                        'part4_secondary': pdfs.part4_secondary,
                    }
                },
                part5:{
                    type: 'category',
                    title: 'home_page.part_5',
                    slug: 'part5',
                    items: {
                        'optimism': videos.optimism,
                        'part5_preschool': pdfs.part5_preschool,
                        'part5_foundation': pdfs.part5_foundation,
                        'part5_primary': pdfs.part5_primary,
                        'part5_secondary': pdfs.part5_secondary,
                    }
                },
                part6:{
                    type: 'category',
                    title: 'home_page.part_6',
                    slug: 'part6',
                    items: {
                        'savouring': videos.savouring,
                        'part6_preschool': pdfs.part6_preschool,
                        'part6_foundation': pdfs.part6_foundation,
                        'part6_primary': pdfs.part6_primary,
                        'part6_secondary': pdfs.part6_secondary,
                    }
                }
            },
            wel: {
                rhan1:{
                    type: 'category',
                    title: 'home_page.part_1',
                    slug: 'rhan1',
                    items: {
                        'ystyr': videos.ystyr,
                        'rhan1_preschool': pdfs.rhan1_preschool,
                        'rhan1_foundation': pdfs.rhan1_foundation,
                        'rhan1_primary': pdfs.rhan1_primary,
                        'rhan1_secondary': pdfs.rhan1_secondary,
                    }
                }, 
                rhan2:{
                    type: 'category',
                    title: 'home_page.part_2',
                    slug: 'rhan2',
                    items: {
                        'diolchgarwch': videos.diolchgarwch,
                        'rhan2_preschool': pdfs.rhan2_preschool,
                        'rhan2_foundation': pdfs.rhan2_foundation,
                        'rhan2_primary': pdfs.rhan2_primary,
                        'rhan2_secondary': pdfs.rhan2_secondary,
                    }
                },
                rhan3:{
                    type: 'category',
                    title: 'home_page.part_3',
                    slug: 'rhan3',
                    items: {
                        'empathi': videos.empathi,
                        'rhan3_preschool': pdfs.rhan3_preschool,
                        'rhan3_foundation': pdfs.rhan3_foundation,
                        'rhan3_primary': pdfs.rhan3_primary,
                        'rhan3_secondary': pdfs.rhan3_secondary,
                    },
                },
                rhan4:{
                    type: 'category',
                    title: 'home_page.part_4',
                    slug: 'rhan4',
                    items: {
                        'caredigrwydd': videos.caredigrwydd,
                        'rhan4_preschool': pdfs.rhan4_preschool,
                        'rhan4_foundation': pdfs.rhan4_foundation,
                        'rhan4_primary': pdfs.rhan4_primary,
                        'rhan4_secondary': pdfs.rhan4_secondary,
                    },
                },
                rhan5:{
                    type: 'category',
                    title: 'home_page.part_5',
                    slug: 'rhan5',
                    items: {
                        'optimistiaeth': videos.optimistiaeth,
                        'rhan5_preschool': pdfs.rhan5_preschool,
                        'rhan5_foundation': pdfs.rhan5_foundation,
                        'rhan5_primary': pdfs.rhan5_primary,
                        'rhan5_secondary': pdfs.rhan5_secondary,
                    },
                },
                rhan6:{
                    type: 'category',
                    title: 'home_page.part_6',
                    slug: 'rhan6',
                    items: {
                        'sawru': videos.sawru,
                        'rhan6_preschool': pdfs.rhan6_preschool,
                        'rhan6_foundation': pdfs.rhan6_foundation,
                        'rhan6_primary': pdfs.rhan6_primary,
                        'rhan6_secondary': pdfs.rhan6_secondary,
                    },
                }
            }
        }[translate.currentLang || translate.defaultLang];

        const urlSegments = activatedRoute.snapshot.url;
        if (urlSegments?.length) { this.isHomepage = false; }
        urlSegments.forEach((segment, i) => {
            if (i === urlSegments.length - 1) { this.pageTitle = items[segment.path].title; }
            this.pageItemsClass = segment.path;
            items = items[segment.path].items as { [key: string]: Category };
        });
        this.list = items;
    }

    ngOnInit(): void { }

    keyvalueSort() {
        return 0;
    }

    redirectToCheckout() {
        this.stripeService.getCheckoutSession().subscribe(
            ({ id: sessionId }) => {
                this.stripe.redirectToCheckout({ sessionId }).subscribe(
                    error => {
                        console.error(error);
                    }
                );
            }
        );
    }

    redirectToCustomerPortal() {
        this.stripeService.getCustomerPortalSession().subscribe(
            ({ url }) => window.location.href = url
        )
    }

    getMenuItemsClass(item: Video | Category) {
        return item?.type === 'video' ? this.pageItemsClass : item?.slug;
    }

    getRouterLinkByType(type: string, slug: string): string {
        switch (type) {
            case 'video':
                return `/video/${slug}`
            case 'pdf':
                return `/pdf/${slug}`
            default:
                return slug
        }
    }
}
