import { PDF } from 'src/app/core/models/pdf.model';

export const pdfs: { [key: string]: PDF } = {
    'part1_preschool': {
        type: 'pdf',
        slug: 'part1_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part1_foundation': {
        type: 'pdf',
        slug: 'part1_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part1_primary': {
        type: 'pdf',
        slug: 'part1_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part1_secondary': {
        type: 'pdf',
        slug: 'part1_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part2_preschool': {
        type: 'pdf',
        slug: 'part2_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part2_foundation': {
        type: 'pdf',
        slug: 'part2_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part2_primary': {
        type: 'pdf',
        slug: 'part2_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part2_secondary': {
        type: 'pdf',
        slug: 'part2_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part3_preschool': {
        type: 'pdf',
        slug: 'part3_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part3_foundation': {
        type: 'pdf',
        slug: 'part3_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part3_primary': {
        type: 'pdf',
        slug: 'part3_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part3_secondary': {
        type: 'pdf',
        slug: 'part3_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part4_preschool': {
        type: 'pdf',
        slug: 'part4_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part4_foundation': {
        type: 'pdf',
        slug: 'part4_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part4_primary': {
        type: 'pdf',
        slug: 'part4_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part4_secondary': {
        type: 'pdf',
        slug: 'part4_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part5_preschool': {
        type: 'pdf',
        slug: 'part5_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part5_foundation': {
        type: 'pdf',
        slug: 'part5_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part5_primary': {
        type: 'pdf',
        slug: 'part5_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part5_secondary': {
        type: 'pdf',
        slug: 'part5_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part6_preschool': {
        type: 'pdf',
        slug: 'part6_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part6_foundation': {
        type: 'pdf',
        slug: 'part6_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part6_primary': {
        type: 'pdf',
        slug: 'part6_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'part6_secondary': {
        type: 'pdf',
        slug: 'part6_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan1_preschool': {
        type: 'pdf',
        slug: 'rhan1_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan1_foundation': {
        type: 'pdf',
        slug: 'rhan1_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan1_primary': {
        type: 'pdf',
        slug: 'rhan1_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan1_secondary': {
        type: 'pdf',
        slug: 'rhan1_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan2_preschool': {
        type: 'pdf',
        slug: 'rhan2_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan2_foundation': {
        type: 'pdf',
        slug: 'rhan2_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan2_primary': {
        type: 'pdf',
        slug: 'rhan2_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan2_secondary': {
        type: 'pdf',
        slug: 'rhan2_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan3_preschool': {
        type: 'pdf',
        slug: 'rhan3_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan3_foundation': {
        type: 'pdf',
        slug: 'rhan3_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan3_primary': {
        type: 'pdf',
        slug: 'rhan3_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan3_secondary': {
        type: 'pdf',
        slug: 'rhan3_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan4_preschool': {
        type: 'pdf',
        slug: 'rhan4_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan4_foundation': {
        type: 'pdf',
        slug: 'rhan4_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan4_primary': {
        type: 'pdf',
        slug: 'rhan4_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan4_secondary': {
        type: 'pdf',
        slug: 'rhan4_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan5_preschool': {
        type: 'pdf',
        slug: 'rhan5_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan5_foundation': {
        type: 'pdf',
        slug: 'rhan5_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan5_primary': {
        type: 'pdf',
        slug: 'rhan5_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan5_secondary': {
        type: 'pdf',
        slug: 'rhan5_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan6_preschool': {
        type: 'pdf',
        slug: 'rhan6_preschool',
        section: 'home_page.support',
        title: 'home_page.preschool',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan6_foundation': {
        type: 'pdf',
        slug: 'rhan6_foundation',
        section: 'home_page.support',
        title: 'home_page.foundation',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan6_primary': {
        type: 'pdf',
        slug: 'rhan6_primary',
        section: 'home_page.support',
        title: 'home_page.primary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
    'rhan6_secondary': {
        type: 'pdf',
        slug: 'rhan6_secondary',
        section: 'home_page.support',
        title: 'home_page.secondary',
        subtitle: null,
        url: `/assets/blank.pdf`
    },
}