import { AuthService } from 'src/app/core/services/auth.service';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class HeadersHttpInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newHeaders: HttpHeaders = req.headers;
        
        if(this.auth.idToken) {
            newHeaders = newHeaders.append('Authorization', this.auth.idToken)
        }

        const newRequest = req.clone({ headers: newHeaders });
        return next.handle(newRequest);
    }
}
