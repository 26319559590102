import { VideoService } from './../core/services/video.service';
import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, OnDestroy, SecurityContext } from '@angular/core';
import * as Hls from 'hls.js';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Video } from '../core/models/video.model';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
	selector: 'app-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {
    
    video: Video;
	url: string;

	@ViewChild('video') videoElRef: ElementRef;
	public videoElement: HTMLVideoElement;

	public hls: Hls;

	constructor(
        private location: Location,
        videoService: VideoService,
        route: ActivatedRoute,
        private sanitizer: DomSanitizer
	) {
        route.params.subscribe(({videoKey}) => {
            this.video = videoService.getOne(videoKey);
            this.url = this.video?.url;

            if(!this.url || !Hls.isSupported()) {
                // TODO: warn the user about missing url / unsupported browser
                this.location.back();
            }
        })
    }
	
	ngOnInit(): void {}

	ngAfterViewInit(): void {
        if(this.url && this.video.type === 'video') {
            this.videoElement = this.videoElRef.nativeElement;
            this.startHlsStream();
        }
    }
	
	ngOnDestroy(): void {
        this.destroyHlsStream();
	}
	
	private startHlsStream(): void {
        if(Hls.isSupported()) {
            this.hls = new Hls();
			this.hls.loadSource(this.url);
			this.hls.attachMedia(this.videoElement);
			this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
				this.videoElement.focus();
				this.videoElement.play();
			});
		}
    }
    
    private destroyHlsStream(): void {
        if(this.hls) {
            this.hls.detachMedia();
            this.hls.destroy();
        }
    }

	public togglePause(): void {
		this.videoElement.paused
			? this.videoElement.play()
			: this.videoElement.pause();
    }
    
    public toggleFullscreen(): void {
        document.fullscreenElement
            ? document.exitFullscreen()
            : this.videoElement.requestFullscreen();
    }

    sanitizeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
