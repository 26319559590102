import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	
	constructor(
		translate: TranslateService,
        auth: AuthService,
		router: Router
	) {


		translate.addLangs(environment.available_languages);
		translate.onLangChange.subscribe((e) => {
            localStorage.setItem(`language`, e.lang);
            if(!['/login', '/terms', '/my-account'].some(urlPart => router.url.includes(urlPart))) {
                // Navigate back home since all other content is language-specific
                router.navigate(['/']);
            }
        });

		
		if(localStorage.getItem('language') == null){
			if(this.isOnWelshDomain()){
				translate.use('wel');
			}
			else{
				translate.use('en');
			}
		}
		else if(localStorage.getItem('language') != null){
			translate.use(localStorage.getItem(`language`) || translate.defaultLang);
		}

		auth.loginEvent$.subscribe(isLoggedIn => {
            if(isLoggedIn) {
				if(['/login', '/forgot-password'].some(url => router.url.includes(url))) {
					router.navigate(['/']);
				}
			}
			else {
				router.navigate(['/login']);
			}
		});
	}

	isOnWelshDomain(): boolean {
		const hostnameParts = window.location.hostname.split('.');
		const domain = hostnameParts[0] === 'www'
			? hostnameParts[1]
			: hostnameParts[0]

		return environment.welshLanguageDomain === domain
	}
}
	