import { environment } from './../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '../../models/stripe.model';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    
    private baseUrl: string = environment.baseUrl + `/stripe`;

    constructor(
        private http: HttpClient
    ) {}

    getProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(this.baseUrl + `/products`);
    }

    getCheckoutSession(): Observable<unknown> {
        return this.http.get(this.baseUrl + `/checkout`);
    }

    getCustomerPortalSession(): Observable<unknown> {
        return this.http.get(this.baseUrl + `/session`);
    }
}
    