<ng-container *ngIf="isHomepage">
    <section class="welcome-banner">
        <h1>{{ 'home_page.welcome' | translate }}</h1>
        <h3 *ngIf="!auth.userStripePaymentStatus">{{ 'home_page.subscribeCTA' | translate }}</h3>
    </section>
</ng-container>
<ng-container>
    <section class="pageTitleSection" *ngIf="pageTitle">
        <div class = "thePageTitle">
            <!-- <div class="pageTitleColorBand" [ngClass]="getPageTitleColorClass()"></div> -->
            <h1 class="pageTitle">{{(pageTitle | translate) || pageTitle}}</h1>
        </div>

        <div id="chime">
            <div class="navigation-icons">
                <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" (click)="this._location.back();"><mat-icon>navigate_before</mat-icon></button>
                <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" [routerLink]="'/'"><mat-icon>house</mat-icon></button>
                <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" (click)="this.auth.logout();"><mat-icon>exit_to_app</mat-icon></button>
            </div>
        </div>
    </section>
    <ng-container *ngIf="isHomepage">
        <mat-card class="menu mat-elevation-z2" tabindex="0" [routerLink]="'/introduction-page'"
            (keyup.enter)="router.navigate(['/introduction-page'])">
            <mat-card-header>
                <mat-card-title class="no-margin">{{ 'home_page.introduction' | translate }}</mat-card-title>
            </mat-card-header>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="!auth.isPaymentConfirmed">
        <mat-card class="menu mat-elevation-z2 lightBlueBtn" tabindex="0" tabindex="0" (click)="redirectToCheckout()" (keyup.enter)="redirectToCheckout()">
            <mat-card-header >
                <mat-card-title class="no-margin">{{ 'home_page.go_to_account' | translate }}</mat-card-title>
            </mat-card-header>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="auth.isPaymentConfirmed">
        <ng-container *ngFor="let item of list | keyvalue: keyvalueSort, let i = index">
            <mat-card-header class="subsection" *ngIf="item.value?.section">
                <mat-card-title class="no-margin title">
                    {{ item.value.section | translate }}
                </mat-card-title>
            </mat-card-header>
        
            <mat-card class="menu mat-elevation-z2" [class.button-full-bg]="item?.value.type === 'video'"
                [routerLink]="getRouterLinkByType(item.value.type, item.value.slug)"
                (keyup.enter)="router.navigate(getRouterLinkByType(item.value.type, item.value.slug), { relativeTo: this.activatedRoute })"
                [ngClass]="getMenuItemsClass(item?.value)">
        
                <mat-card-header>
                    <mat-card-title [ngClass]="{ 'no-margin': !item.value || !item.value[listConfig.headerTextProperty] }">
                        {{ (item.value && item.value[listConfig.titleTextProperty] | translate) || item.value && item.value[listConfig.titleTextProperty] }}
                    </mat-card-title>
                </mat-card-header>
                <span class="duration" *ngIf="item.value && item.value[listConfig.durationProperty]">
                    {{ item.value && item.value[listConfig.durationProperty] * 1000 | date:'m:ss' }}
                </span>
            </mat-card>
        </ng-container>
    </ng-container>
</ng-container>
