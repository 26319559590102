import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundImageService {
  
  backgroundClasses = {
    'introduction-page': 'introduction-page',
    'part1': 'meaning',
    'rhan1': 'meaning',
    'part2': 'gratitude',
    'rhan2': 'gratitude',
    'part3': 'empathy',
    'rhan3': 'empathy',
    'part4': 'kindness',
    'rhan4': 'kindness',
    'part5': 'optimism',
    'rhan5': 'optimism',
    'part6': 'savouring',
    'rhan6': 'savouring',
  }
  
  defaultBackgroundClass = 'main-menu';
  currentBackgroundClass = this.defaultBackgroundClass;
  
  constructor(
    router: Router
  ) {
    router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        const urlParts = event.url.split('/');
        const currentRoute = urlParts[urlParts.length - 1]
        this.currentBackgroundClass = this.backgroundClasses[currentRoute] || this.defaultBackgroundClass;
      }
    })
  }
}
  