<section class="pageTitleSection">
    <div>
        <h1 class="pageTitle">{{ 'sidenav_menu.my_account' | translate }}</h1>
    </div>

    <div id="chime">
        <div class="navigation-icons">
            <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" [routerLink]="'/'"><mat-icon>navigate_before</mat-icon></button>
            <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" [routerLink]="'/'"><mat-icon>house</mat-icon></button>
            <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" (click)="this.auth.logout();"><mat-icon>exit_to_app</mat-icon></button>
        </div>
    </div>
</section>

<!-- My email -->
<!-- <mat-card class="menu info-card green">
    <mat-card-header>
        <mat-card-subtitle>{{ 'auth.username' | translate }}</mat-card-subtitle>
        <mat-card-title>{{ auth.userEmail }}</mat-card-title>
    </mat-card-header>
</mat-card> -->
<!-- My Group name -->
<ng-container [ngTemplateOutlet]="subscriptionStatus"></ng-container>

<!-- Actions - Not a member -->
<ng-container *ngIf="!auth.userStripePaymentId" [ngTemplateOutlet]="initialActions"></ng-container>

<ng-template #subscriptionStatus>
    <mat-card class="menu info-card" 
        [ngClass]="{
            'red': !auth.isPaymentConfirmed,
            'green': auth.isPaymentConfirmed
        }"
    >
        <mat-card-header>
            <mat-card-subtitle>{{ 'subscription.status' | translate }}</mat-card-subtitle>
            <mat-card-title>
                <ng-container [ngSwitch]="auth.userStripePaymentStatus">
                    <ng-container *ngSwitchCase="'paid'">{{ 'subscription.status_active' | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'unpaid'">{{ 'subscription.status_unpaid' | translate }}</ng-container>
                    <ng-container *ngSwitchDefault>{{ 'subscription.status_none' | translate }}</ng-container>
                </ng-container>
            </mat-card-title>
        </mat-card-header>
    </mat-card>
</ng-template>

<ng-template #initialActions>
    <mat-card class="menu blue mat-elevation-z2" tabindex="0" (click)="redirectToCheckout()" (keyup.enter)="redirectToCheckout()">
        <mat-card-header>
            <mat-card-title class="no-margin">{{ 'subscription.subscribe' | translate }}</mat-card-title>
        </mat-card-header>
    </mat-card>
</ng-template>