<mat-toolbar>
    <button mat-icon-button color="primary" aria-label="Sidebar menu button" (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button color="primary" aria-label="Select language" 
    (click)="presentLanguageMenu()">
        <img class="imgClass" src="assets/images/lang.png">
    </button>

    <span class="spacer"></span>
    <span class="title">{{ 'app_title' | translate }}</span>
    <span class="spacer"></span>
    
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" class="dark-accent-background" (click)="sidenav.toggle()">
        <mat-nav-list>
            <mat-list-item (keyup.enter)="navigate('/')" tabindex="0" routerLink='/'>
                <mat-icon class="dark-accent">home</mat-icon>
                <span>{{ 'sidenav_menu.home' | translate }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="!auth.isPaymentConfirmed" (keyup.enter)="navigate('/my-account')" tabindex="0" routerLink='/my-account'>
                <mat-icon class="dark-accent">card_membership</mat-icon>
                <span>{{ 'sidenav_menu.my_account' | translate }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item (keyup.enter)="presentLanguageMenu()" tabindex="0" (click)='presentLanguageMenu()'>
                <mat-icon class="dark-accent">translate</mat-icon>
                <span>{{ 'sheet_menu.select_language' | translate }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item (keyup.enter)="navigate('/terms')" tabindex="0" routerLink='/terms'>
                <mat-icon class="dark-accent">policy</mat-icon>
                <span>{{ 'sidenav_menu.terms' | translate }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item (keyup.enter)="auth.logout()" tabindex="0" (click)='auth.logout()'>
                <mat-icon class="dark-accent">power_settings_new</mat-icon>
                <span>{{ 'sheet_menu.logout' | translate }}</span>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="background-image" [ngClass]="backgroundImageService.currentBackgroundClass">
            <!-- <div class="route-animations-wrapper" [@routeAnimations]="prepareRoute(outlet)"> -->
            <div class="route-animations-wrapper">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>    
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>