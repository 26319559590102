import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'app-invite-code-dialog',
    templateUrl: './invite-code-dialog.component.html',
    styleUrls: ['./invite-code-dialog.component.scss']
})
export class InviteCodeDialogComponent implements OnInit {
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public code: string
    ) {}
    
    ngOnInit(): void {}
    
}
    