<!-- <h1 class="pageTitle">{{ 'introduction_page.introduction' | translate }}</h1>
<div id="chime">
    <div class="navigation-icons">
        <button mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1"[routerLink]="'/'" ><mat-icon>navigate_before</mat-icon></button>
        <button mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" [routerLink]="'/'"><mat-icon>house</mat-icon></button>
        <button mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" (click)="this.auth.logout();"><mat-icon>exit_to_app</mat-icon></button>
    </div>
</div> -->


<section class="pageTitleSection">
    <div>
        <h1 class="pageTitle">{{ 'introduction_page.introduction' | translate }}</h1>
    </div>

    <div id="chime">
        <div class="navigation-icons">
            <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" [routerLink]="'/'"><mat-icon>navigate_before</mat-icon></button>
            <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" [routerLink]="'/'"><mat-icon>house</mat-icon></button>
            <button class="navBtns" mat-icon-button [hidden]="false" aria-hidden="false" tabindex="-1" (click)="this.auth.logout();"><mat-icon>exit_to_app</mat-icon></button>
        </div>
    </div>
</section>

<ng-container >
    
    <mat-card class="menu mat-elevation-z2 introCard" tabindex="0" [routerLink]="translate.currentLang === 'en' ? '/video/introduction' : '/video/cyflwyniad'">
        <mat-card-header  class="webinar lineHeight">
            <mat-card-title class="no-margin boxTitle2">{{ 'introduction_page.intro_title' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-header class="webinarSubtitle">
            <mat-card-subtitle class="no-margin">{{ 'introduction_page.intro_description' | translate }}</mat-card-subtitle>
        </mat-card-header>
    </mat-card>

    <mat-card class="menu mat-elevation-z2 introCard" tabindex="0" [routerLink]="translate.currentLang === 'en' ? '/video/webinar' : '/video/gweminar'">
        <mat-card-header class="webinar">
            <mat-card-title class="no-margin boxTitle2">{{ 'introduction_page.webinar_title' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-header class="webinarSubtitle">
            <mat-card-subtitle class="no-margin">{{ 'introduction_page.webinar_description' | translate }}</mat-card-subtitle>
        </mat-card-header>
    </mat-card>
    <mat-card  class="menu mat-elevation-z2 termsCard" tabindex="0" [routerLink]="'/terms'">
        <mat-card-header class="termSubtitle">
            <mat-card-title class="no-margin termsTitle">{{ 'terms.terms_title' | translate }}</mat-card-title>
        </mat-card-header>
    </mat-card>
</ng-container>