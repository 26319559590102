import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { IntroductionPageComponent } from './introduction-page/introduction-page.component';
import { ListComponent } from './shared/list/list.component';
import { VideoComponent } from './video/video.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TermsComponent } from './terms/terms.component';
import { PdfComponent } from './pdf/pdf.component';

class ReuseStrategy implements RouteReuseStrategy {
    handlers: {[key: string]: DetachedRouteHandle} = {};

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot) {
        return false;
    }
}

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { animation: 'Login' }
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    data: { animation: 'SignUp' }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { animation: 'ForgotPassword' }
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ListComponent, data: { animation: 'List' } },
      { path: 'my-account', component: MyAccountComponent, data: { animation: 'Account' } },
      { path: 'video/:videoKey', component: VideoComponent, data: { animation: 'Video' } },
      { path: 'pdf/:pdfKey', component: PdfComponent, data: { animation: 'PDF' } },
      { path: 'terms', component: TermsComponent, data: { animation: 'List' } },
      { path: 'introduction-page', component: IntroductionPageComponent, data: { animation: 'List' } },
      { path: '**', component: ListComponent, data: { animation: 'List' } },
    ]
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [
        { provide: RouteReuseStrategy, useClass: ReuseStrategy }
    ]
})
export class AppRoutingModule { }
