import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { pdfs } from 'src/assets/pdfs';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {

  url: SafeResourceUrl;

  constructor(
    route: ActivatedRoute,
    location: Location,
    sanitizer: DomSanitizer
  ) {
    route.params.subscribe(({pdfKey}) => {
      const url = pdfs[pdfKey]?.url;
      if(url) {
        this.url = sanitizer.bypassSecurityTrustResourceUrl(url);
      } else {
        location.back();
      }
    })
  }

  ngOnInit(): void {}
}
