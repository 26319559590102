import { EqualValidatorDirective } from './core/directives/equal-validator.directive';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Provider } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxStripeModule } from 'ngx-stripe';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';

import { HeadersHttpInterceptor } from './core/interceptors/headers-http-interceptor';
import { ErrorHttpInterceptor } from './core/interceptors/error-http-interceptor';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MainComponent } from './main/main.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { VideoComponent } from './video/video.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginService } from './core/services/api/login.service';
import { AuthService } from './core/services/auth.service';
import { TermsComponent } from './terms/terms.component';
import { IntroductionPageComponent } from './introduction-page/introduction-page.component';


// Mocks used for offline development
import { MockLoginService } from './core/services/api/mock-login.service';
import { MockAuthService } from './core/services/mock-auth.service';
import { PdfComponent } from './pdf/pdf.component';
const MOCK_PROVIDERS: Provider[] = [
    { provide: AuthService, useValue: new MockAuthService() },
    { provide: LoginService, useValue: new MockLoginService() }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
      // console.error(`Missing translation: "${params.key}"`);
      return undefined;
  }
}

export const matBottomSheetConfig: MatBottomSheetConfig = {
    autoFocus: true,
    closeOnNavigation: true,
    hasBackdrop: true,
    backdropClass: 'dark-accent-background',
    panelClass: ['action-sheet']
}
export const matDialogConfig: MatDialogConfig = {
    autoFocus: true,
    closeOnNavigation: true,
    hasBackdrop: true,
    backdropClass: 'dark-accent-background',
    width: '500px'
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    MainComponent,
    VideoComponent,
    MyAccountComponent,
    ForgotPasswordComponent,
    EqualValidatorDirective,
    TermsComponent,
    IntroductionPageComponent,
    PdfComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.default_language,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('token'),
        allowedDomains: [],
        disallowedRoutes: []
      }
    }),
    SharedModule,
    NgxStripeModule.forRoot(environment.stripeKey),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeadersHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: matBottomSheetConfig },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: matDialogConfig },
    // Mocks used for offline development. Uncomment this line to enable:
    //   ...MOCK_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}