<header>
    <img class="logo" src="assets/images/gwylanlogo.jpg" alt="">
    <span class="title">{{ 'app_title' | translate }}</span>
</header>
<mat-card class="mat-elevation-z0">
    <mat-card-header>
        <mat-card-subtitle>{{ 'auth.registerCTA' | translate }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content (keydown.enter)="submitForm()">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.username' | translate }}</mat-label>
            <mat-icon matPrefix>mail_outline</mat-icon>
            <input matInput [(ngModel)]="email" id="username" name="username" type="email" required>
            <mat-error>{{ 'auth.username_validation_error' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.name' | translate }}</mat-label>
            <mat-icon matPrefix>face</mat-icon>
            <input matInput [(ngModel)]="name" type="text" required>
            <mat-error>{{ 'auth.name_validation_error' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.password' | translate }}</mat-label>
            <mat-icon matPrefix>vpn_key</mat-icon>
            <input matInput [(ngModel)]="password" type="password" required minlength="8">
            <mat-error>{{ 'auth.password_validation_error' | translate }}</mat-error>
        </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button color="accent" (click)="submitForm()">{{ 'auth.signUp' | translate }}</button>
    </mat-card-actions>
    <a class="register" routerLink="/login">
        {{ 'auth.use_existing_account' | translate }}
    </a>
</mat-card>