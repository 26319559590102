import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { RefreshRequest } from '../../models/refresh-request.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private _httpClient: HttpClient) { }

  baseUrl: string = environment.baseUrl + '/login/refresh';

  post(req: RefreshRequest, params?: any): Observable<any> {
    return this._httpClient.post<RefreshRequest>(`${this.baseUrl}`, req);
  }
}
