import { trigger, query, animateChild, style, group, animate, transition, keyframes } from '@angular/animations';
export const slideInAnimation =
    trigger('routeAnimations', [
        // transition("void => List, void => Plans", [
        //     animate(300, keyframes([
        //         style({transform: 'translateX(100%)', opacity: 0}),
        //         style({transform: 'translateX(0)', opacity: 1})
        //     ]))
        //     ]
        // ),
        transition("* <=> *", [
            animate(300, keyframes([
                style({opacity: 0}),
                style({opacity: 1})
            ]))
            ]
        )
    ]);